// layouts
import main from "@/layout/main.vue";
import empty from "@/layout/empty.vue";

// landing
const Home = () => import("@/pages/home/index.vue");

const routes = [
  {
    path: "/",
    component: main,
    props: true,
    children: [
      // landing
      { name: "home", path: "/", component: Home, meta: { entryPoint: true } },
    ],
  },

  {
    path: "/:catchAll(.*)*",

  },
];

export default routes;
