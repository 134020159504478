import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueToast from 'vue-toast-notification';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueToast, {
    // One of the options
    position: 'top-right'
})

app.use(NProgress)


app.mount('#app')

