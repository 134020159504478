import { createRouter, createWebHistory } from 'vue-router'
import routes from "./routes";
import axios from 'axios'

// axios interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    NProgress.start();
    return config;
  }, function (error) {
    // Do something with request error
    NProgress.done();
    console.error(error)
    return Promise.reject(error);
  });
  
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    NProgress.done();
    return response;
  }, function (error) {
    // Do something with response error
    NProgress.done();
    console.error(error)
    return Promise.reject(error);
  });

// import router
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});

router.beforeEach(function (transition) {
    NProgress.start();
  });
  
  router.afterEach(function (transition) {
    NProgress.done();
    NProgress.remove();
  });

// authentication driver
const validateLogin = () => {
    try {
        var active = localStorage.getItem("cred");
        if (active == "active") {
            return true;
        }
    } catch (e) {
        return false;
    }
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (validateLogin()) {
            next({
                path: 'login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
